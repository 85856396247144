* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 1.4;
  color: #5b5b5d;
  background: #f5f5f5;
}

input, textarea {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
}

.hidden {
  display: none !important;
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.layout_scroll_blocked {
  overflow: hidden;
}

.container {
  position: relative;
  flex: 1 1 0;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.container_type_limited {
  width: 290px;
}
.container_type_tough {
  flex: none;
}
.container_type_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container_space_regular {
  padding: 32px 0 48px;
}
.container_space_regular + .container_space_regular {
  padding-top: 0;
}
.container_space_section {
  padding: 64px 0 96px;
}
.container_space_narrow {
  padding: 24px 0;
}
.container__heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
@media (min-width: 375px) {
  .container_type_limited {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .container_type_limited {
    width: 390px;
  }
}
@media (min-width: 768px) {
  .container_type_limited {
    width: 736px;
  }
  .container_type_sticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}
@media (min-width: 1200px) {
  .container_type_limited {
    width: 1096px;
  }
}

.autocomplete {
  position: relative;
  text-align: left;
}
.autocomplete__input {
  width: 100%;
  height: 48px;
  outline: none;
  border: 1px solid #c3c3c4;
  border-radius: 24px;
  padding-left: 24px;
  padding-right: 48px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}
.autocomplete__input:focus {
  border-color: #5b5b5d;
}
.autocomplete__input_type_placeholder {
  background-color: #e6e6e6;
}
.autocomplete__input_size_small > input {
  height: 40px;
  padding: 0 12px;
  font-size: 16px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.autocomplete__result {
  position: relative;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
}
.autocomplete__result:hover {
  background: #e6e6e6;
}
.autocomplete__result_type_history, .autocomplete__result_type_current {
  padding-left: 40px;
}
.autocomplete__result_type_history:before, .autocomplete__result_type_current:before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 20px;
  left: 12px;
  width: 18px;
  height: 18px;
  background-size: cover;
}
.autocomplete__result_type_no {
  padding-left: 32px;
}
.autocomplete__result_type_current:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%235B5B5D"><path d="M500 232h-29.334C459.597 131.885 380.115 52.403 280 41.334V12c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v29.334C131.885 52.403 52.403 131.885 41.334 232H12c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h29.334C52.403 380.115 131.885 459.597 232 470.666V500c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12v-29.334C380.115 459.597 459.597 380.115 470.666 280H500c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12zM280 422.301V380c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v42.301C158.427 411.84 100.154 353.532 89.699 280H132c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12H89.699C100.16 158.427 158.468 100.154 232 89.699V132c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V89.699C353.573 100.16 411.846 158.468 422.301 232H380c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h42.301C411.84 353.573 353.532 411.846 280 422.301zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z"/></svg>');
}
.autocomplete__result_type_history:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%235B5B5D"><path d="M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659 16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708 22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651 12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058 90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z"/></svg>');
}
.autocomplete__result-name {
  font-weight: bold;
}
.autocomplete__result-type {
  font-size: 14px;
  color: #9c9c9c;
}
.autocomplete__terms {
  font-size: 14px;
  color: #9c9c9c;
  margin-left: 6px;
}
.autocomplete_mb_16 {
  margin-bottom: 16px;
}

.popup .autocomplete__input {
  border: none;
  height: 30px;
  padding: 0 0 4px;
  border-bottom: 1px dotted #5b5b5d;
  border-radius: 0;
  font-size: 20px;
  color: #f27135;
  width: 250px;
}
@media (min-width: 768px) {
  .popup .autocomplete__input {
    width: 100%;
  }
}

.link {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  color: #5b5b5d;
}
.link:hover {
  color: #ea6100;
}
.link:active {
  color: #f28b5f;
}
.link > svg {
  fill: #5b5b5d;
}
.link:hover > svg {
  fill: #ea6100;
}
.link:active > svg {
  fill: #f28b5f;
}
.link > svg {
  width: 14px;
  height: 14px;
  fill: #5b5b5d;
}
.link_type_action, .link_type_call, .link_type_accent {
  padding-bottom: 2px;
  border-bottom: 1px dotted #5b5b5d;
  text-decoration: none;
}
.link_type_call, .link_type_simple {
  color: #f27135;
}
.link_type_camo, .link_type_simple {
  text-decoration: none;
  border-bottom: none;
}
.link_type_camo {
  color: inherit;
}
.link_type_accent {
  color: #f27135;
  font-weight: bold;
}
.link_type_tough {
  white-space: nowrap;
}
.link_type_icon {
  display: inline-block;
  font-size: 0;
  text-decoration: none;
}
.link_type_icon > svg {
  width: 24px;
  height: 24px;
}
.link_type_icon + .link_type_icon {
  margin-left: 8px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 0;
}
.dropdown__options {
  position: absolute;
  display: none;
  width: 200px;
  max-height: 250px;
  left: 0;
  top: 0;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  background: #fff;
  border: 1px solid #c3c3c4;
  border-radius: 2px;
}
.dropdown__options_state_opened {
  display: block;
}
.dropdown__options_render_outside {
  position: initial;
  top: 0;
  left: 0;
  transform: none;
  margin-top: 4px;
}
.dropdown__option {
  color: #f27135;
  font-size: 20px;
  line-height: 40px;
  cursor: pointer;
}
.dropdown__option:hover {
  background: #e6e6e6;
}
.dropdown__option_state_selected, .dropdown__option_state_selected:hover {
  background: #f27135;
  color: #fff;
}
.dropdown__value {
  display: inline-block;
  padding-bottom: 4px;
  border-bottom: 1px dotted #5b5b5d;
  font-size: 20px;
  color: #f27135;
  cursor: pointer;
}
.dropdown__label {
  font-size: 12px;
  color: #9c9c9c;
}
.dropdown_state_opened .dropdown__options {
  display: block;
}
@media (min-width: 768px) {
  .dropdown__options {
    width: 220px;
  }
}

.tether-dropdown-not-inited {
  left: -100%;
}

.tether-dropdown-element {
  z-index: 100;
}

.checkbox-list {
  position: relative;
  display: inline-block;
  margin: 0;
}
.checkbox-list__text {
  color: #f27135;
  border-bottom: 1px dotted #5b5b5d;
  padding-bottom: 4px;
  cursor: pointer;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  width: 280px;
  max-height: 250px;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  background: #fff;
  border: 1px solid #c3c3c4;
  padding: 12px 16px;
  border-radius: 2px;
  font-size: 16px;
  text-align: left;
}
.checkbox-list__options_state_opened {
  display: block;
}
.checkbox-list__options_render_outside {
  position: initial;
  top: 0;
  left: 0;
  transform: none;
  margin-top: 4px;
}
.checkbox-list__option {
  padding: 4px 0;
}
.checkbox-list__option_checked .checkbox-list__box, .checkbox-list__option_state_checked .checkbox-list__box {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="%23F27135"><path d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"/></svg>');
}
.checkbox-list__option_group {
  font-weight: bold;
  font-size: 18px;
}
.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option + .checkbox-list__option_group {
  margin-top: 16px;
}
.checkbox-list__box {
  position: relative;
  top: 2px;
  margin-right: 8px;
  cursor: pointer;
  display: inline-block;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="%235B5B5D"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.checkbox-list__label {
  cursor: pointer;
  color: #5b5b5d;
}
.checkbox-list_opened .checkbox-list__options, .checkbox-list_state_opened .checkbox-list__options {
  display: block;
}

.popup__row .checkbox-list__text {
  font-size: 20px;
}

.search-bar__options {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
}
.search-bar__dropdown {
  display: inline-block;
  margin: 0 8px;
}
.search-bar__textbox {
  height: 48px;
  padding-right: 48px;
}
.search-bar__query {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.search-bar__operations, .search-bar__main-filters {
  display: none;
}
.search-bar__submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}
.search-bar__submit:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  top: 14px;
  left: 8px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%239C9C9C"><path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"/></svg>');
  background-size: cover;
}
.search-bar__submit:hover:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23F27135"><path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"/></svg>');
}
@media (min-width: 768px) {
  .search-bar__options {
    font-size: 32px;
  }
}

.search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}
.search-submit:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%239C9C9C"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>');
  background-size: cover;
}
.search-submit:hover:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23F27135"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>');
}

.splitter {
  display: inline-block;
  padding: 0 4px;
  vertical-align: top;
}
.splitter_type_large {
  padding: 0 8px;
}

.menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 11;
  background: #fff;
  border-top: 1px solid #c3c3c4;
}
.menu svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu__bar {
  display: flex;
  width: 320px;
  height: 60px;
  margin: 0 auto;
  justify-content: center;
  flex-direction: row-reverse;
}
.menu__bar-item {
  flex: 0 0 80px;
  height: 60px;
  vertical-align: top;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #5b5b5d;
}
.menu__bar-item_type_button {
  flex: 0 0 138px;
  margin-top: 10px;
}
.menu__bar-icon {
  position: relative;
  width: 18px;
  height: 28px;
  margin: 6px auto 0;
}
.menu__bar-icon > svg {
  width: 100%;
  fill: #5b5b5d;
}
.menu__popup {
  position: relative;
  display: none;
  padding: 72px 0;
  background: #f27135;
}
.menu__backdrop {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
}
.menu__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  z-index: 100;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  background-color: tranparent;
}
.menu__close:hover {
  background-color: #fff;
}
.menu__close:active {
  background-color: #f5f5f5;
}
.menu__close > svg {
  fill: #fff;
}
.menu__close:hover > svg {
  fill: #ea6100;
}
.menu__close:active > svg {
  fill: #f28b5f;
}
.menu__close > svg {
  width: 14px;
}
.menu__links {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu__link {
  position: relative;
  display: block;
  line-height: 48px;
  text-decoration: none;
  cursor: pointer;
  font-size: 24px;
  padding: 0 24px;
  color: #fff;
}
.menu__link svg {
  fill: #fff;
}
.menu__link:hover {
  background: #fff;
}
.menu__link:hover svg {
  fill: #ea6100;
}
.menu__link:active {
  background: #fff;
}
.menu__link:active svg {
  fill: #f28b5f;
}
.menu__link_type_category > .menu__key_type_close {
  display: none;
}
.menu__link_type_sub {
  padding-left: 44px;
  font-size: 20px;
}
.menu__link_state_opened > .menu__key_type_close {
  display: block;
}
.menu__link_state_opened > .menu__key_type_open {
  display: none;
}
.menu__key {
  position: absolute;
  width: 64px;
  height: 48px;
  right: 0;
  top: 0;
}
.menu__key > svg {
  width: 24px;
  height: 24px;
}
.menu__category {
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.menu__category_state_opened {
  height: auto;
  max-height: 200px;
}
.menu__socials {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: 16px;
  text-align: center;
}
.menu__social {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: top;
}
.menu__social + .menu__social {
  margin-left: 20px;
}
.menu__social > svg {
  fill: #fff;
}
.menu__social:hover > svg {
  fill: #ea6100;
}
.menu__social:active > svg {
  fill: #f28b5f;
}
.menu__social > svg {
  fill: #fff;
}
.menu__social:hover, .menu__social:active {
  border-radius: 20px;
  background: #fff;
}
.menu__social_type_facebook > svg {
  width: 17px;
}
.menu__social_type_twitter > svg {
  width: 28px;
}
.menu__social_type_instagram > svg {
  width: 25px;
}
.menu_state_active {
  display: block;
  position: fixed;
  border-top: none;
  height: 100%;
  opacity: 1;
  z-index: 100;
}
.menu_state_active .menu__bar {
  display: none;
}
.menu_state_active .menu__popup {
  display: block;
  height: 100%;
}
.menu_animation_in {
  animation-name: menuSlideInUp;
  animation-duration: 0.3s;
}
.menu_animation_in .menu__link {
  animation-name: menuSlideInLeft;
  animation-duration: 0.6s;
}
.menu_animation_in .menu__socials {
  animation-name: menuSlideInUpSocials;
  animation-duration: 0.5s;
}
.menu_animation_out {
  animation-name: menuSlideOutDown;
  animation-duration: 0.3s;
}
.menu_animation_in, .menu_animation_out {
  z-index: 100;
}
@media (min-width: 375px) {
  .menu__bar {
    width: 360px;
  }
  .menu__bar-item {
    flex: 0 0 90px;
  }
  .menu__bar-item_type_button {
    flex: 0 0 138px;
  }
}
@media (min-width: 425px) {
  .menu__bar {
    width: 420px;
  }
  .menu__bar-item {
    flex: 0 0 105px;
  }
  .menu__bar-item_type_button {
    flex: 0 0 138px;
  }
}
@media (min-width: 768px) {
  .menu {
    height: 100%;
    visibility: hidden;
    background: transparent;
  }
  .menu__bar {
    display: none;
  }
  .menu__popup {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    z-index: 100;
    transition: width ease 0.3s;
    padding: 88px 0;
  }
  .menu__link {
    padding: 0 32px;
  }
  .menu__link_type_sub {
    padding-left: 52px;
  }
  .menu_state_active {
    visibility: visible;
  }
  .menu_state_active .menu__popup {
    width: 400px;
  }
  .menu_state_active .menu__backdrop {
    transition: opacity ease 0.5s;
    visibility: visible;
    opacity: 1;
  }
  .menu_animation_in, .menu_animation_out {
    animation: none;
  }
}

@keyframes menuSlideInUp {
  0% {
    height: 60px;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
@keyframes menuSlideOutDown {
  0% {
    height: 100%;
  }
  100% {
    height: 60px;
  }
}
@keyframes menuSlideInUpSocials {
  0%, 30% {
    bottom: -30px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes menuSlideInLeft {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  display: flex;
  background-color: #f27135;
  height: 64px;
}
.header .link_with_icon > svg {
  fill: #fff;
}
.header__left-pane {
  position: relative;
  flex: 0 0 90px;
  background-color: #e85b1d;
}
.header__left-pane > .link_with_icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header__left-pane > .link_with_icon > svg {
  position: absolute;
  right: 8px;
  top: 22px;
  width: 20px;
  height: 20px;
}
.header__center-pane {
  flex: 1 1 auto;
  padding: 6px 12px 0;
}
.header__right-pane {
  display: none;
  flex: 0 0 40px;
}
.header__right-pane > .link_with_icon {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 18px;
}
.header__right-pane > .link_with_icon > svg {
  width: 32px;
  height: 24px;
}
.header__menu {
  display: none;
}
.header__logo {
  display: inline-block;
  width: 54px;
  padding-top: 12px;
  padding-left: 16px;
  overflow: hidden;
}
.header__logo > svg {
  width: 228px;
}
.header__search {
  max-width: 300px;
}
@media (min-width: 425px) {
  .header__right-pane {
    display: block;
  }
}
@media (min-width: 768px) {
  .header__right-pane {
    flex-basis: 80px;
  }
  .header__right-pane > .link_with_icon {
    padding-left: 12px;
    padding-top: 14px;
  }
  .header__right-pane > .link_with_icon > svg {
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .header__left-pane {
    flex-basis: auto;
  }
  .header__left-pane > .link_with_icon {
    display: none;
  }
  .header__center-pane {
    display: flex;
    padding-left: 24px;
  }
  .header__logo {
    width: auto;
    padding-right: 16px;
  }
  .header__search {
    flex: 0 1 300px;
    max-width: none;
  }
  .header__menu {
    display: block;
    flex: 1 0 auto;
    padding-left: 24px;
    padding-top: 10px;
    text-align: right;
  }
  .header__menu .link {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-size: 20px;
    font-weight: 300;
  }
  .header__menu .link:hover {
    border-bottom: 3px solid #fff;
  }
  .header__menu .link + .link {
    margin-left: 8px;
  }
}
@media (min-width: 1200px) {
  .header__logo {
    padding: 12px 24px 0;
  }
  .header__search {
    flex-basis: 400px;
  }
  .header__menu .link + .link {
    margin-left: 16px;
  }
}
@media (min-width: 1600px) {
  .header__menu .link + .link {
    margin-left: 24px;
  }
}

.header_type_transparent {
  background-color: transparent;
}
.header_type_transparent .header__left-pane {
  flex-basis: 280px;
  background-color: transparent;
}
.header_type_transparent .header__right-pane {
  display: block;
}
.header_type_transparent .header__center-pane {
  padding: 0;
}
.header_type_transparent .header__logo {
  width: auto;
}
.header_type_transparent .link_with_icon > svg {
  fill: #5b5b5d;
}
.header_type_transparent .link_with_icon:hover > svg {
  fill: #f27135;
}
.header_type_transparent .header__menu .link {
  color: #5b5b5d;
}
.header_type_transparent .header__menu .link:hover {
  color: #f27135;
  border-bottom-color: #f27135;
}
@media (min-width: 768px) {
  .header_type_transparent .header__right-pane > .link_with_icon {
    padding-left: 24px;
  }
}
@media (min-width: 1024px) {
  .header_type_transparent .header__center-pane {
    padding-top: 6px;
    padding-left: 24px;
  }
}

.footer {
  padding: 48px 0 92px;
  text-align: center;
  background: #e6e6e6;
  border-top: 1px solid #f2f2f2;
}
.footer__about {
  margin-bottom: 16px;
}
.footer__areas {
  font-size: large;
}
.footer__areas-text {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .footer {
    padding-bottom: 32px;
  }
  .footer__about {
    line-height: 16px;
  }
}